var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-grid" },
    [
      _c(
        "v-container",
        { staticClass: "category-block-header" },
        [_c("category-title", { attrs: { category: _vm.category } })],
        1
      ),
      _vm._l(
        _vm.$ebsn.meta(_vm.category, "template_model.GRID_CONFIG.rows"),
        function(row, rowIndex) {
          return _c(
            "div",
            { key: rowIndex, style: row.style },
            [
              row.container
                ? _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { class: `row-number-${rowIndex}` },
                        _vm._l(row.cols, function(column, columnIndex) {
                          return _c(
                            "v-col",
                            {
                              key: columnIndex,
                              staticClass: "py-0",
                              class: `col-number-${columnIndex}`,
                              attrs: {
                                cols: column.xs || 12,
                                sm: column.sm,
                                md: column.md,
                                lg: column.lg,
                                xl: column.xl
                              }
                            },
                            [
                              _c("category-block", {
                                attrs: {
                                  position: column.position,
                                  target: _vm.category,
                                  container: false
                                }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    { key: rowIndex, class: `row-number-${rowIndex}` },
                    _vm._l(row.cols, function(column, columnIndex) {
                      return _c(
                        "v-col",
                        {
                          key: columnIndex,
                          class: `col-number-${columnIndex}`,
                          attrs: {
                            cols: column.xs || 12,
                            sm: column.sm,
                            md: column.md,
                            lg: column.lg,
                            xl: column.xl
                          }
                        },
                        [
                          _c("category-block", {
                            attrs: {
                              position: column.position,
                              target: _vm.category,
                              container: column.container
                                ? column.container
                                : true
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
            ],
            1
          )
        }
      ),
      _c("ebsn-meta", {
        staticClass: "category-block-footer",
        attrs: {
          target: _vm.category,
          path: "category_info.FOOTER_DESCRIPTION",
          tag: "div"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }